import { Loading, Message } from 'element-ui'
import utils from '../../utils'
export default {
  data() {
    return {
      imageUrl: [],
      showImageViewer: false,
      options: {
        text: '正在获取文件地址...'
      },
      defferScroll: function (event) {
        event.preventDefault()
      }
    }
  },
  methods: {
    async preview(file, id) {
      this.loadingInstance = Loading.service(this.options)
      const {docLibId, fileType} = file
      var _types = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      const pdfType = ['pdf', 'PDF']
      if (_types.includes(fileType)) {
        this.closePageScroll()
        this.imageUrl = []
        const url = await this.getImgUrl(docLibId)
        this.showImageViewer = true
        this.loadingInstance.close()
        this.imageUrl.push(url)
      } else {
        this.$request.post("/file/filePreview", { data: [{ "fileId": docLibId, "contractId": id }]}, {
          headers: {
            "Token": this.Token ? this.Token : ''
          }
        }).then(res => {
          const { code, data } = res
          if (Number(code) === 200 && data) {
            this.loadingInstance.close()
            if (res.code === '200') window.open(res.data)

            // 先判断是否是pdf，如果是直接打开，不是pdf则请求文档库接口，获取office预览地址
            // if (pdfType.includes(fileType)) {
            //   this.loadingInstance.close()
            //   window.open(data)
            // } else {
            //   const urlParams = utils.parseQueryString(data)
            //   const versionId = urlParams.versionId
            //   const hostname = window.location.hostname
            //   let env = 'cms-doc-uat'
            //   if (hostname === 'cms.platanus.com') {
            //     env = 'cms-doc'
            //   }
            //   this.$request.get(`https://${env}.platanus.com/doclogic/v1/wopi/url?r=${new Date().getTime()}&fileId=${docLibId}&versionId=${versionId}`).then(resp => {
            //     if (Number(resp.code) === 200 && resp.data) {
            //       this.loadingInstance.close()
            //       window.open(resp.data)
            //     } else {
            //       this.loadingInstance.close()
            //       Message.error('获取预览地址失败！')
            //     }
            //   })
            // }
          } else {
            this.loadingInstance.close()
            Message.error('获取预览地址失败！')
          }
          // if (res.code === '200') window.open(res.data)
        })
      }
    },
    // 关闭弹层
    closeViewer(e) {
      this.openPageScroll()
      this.showImageViewer = false
    },
    getImgUrl(fileId) {
      return this.$request.get('/file/fileUrl' + '?id=' + fileId, {
        headers: {
          "Token": this.Token ? this.Token : ''
        }
      }).then(res => {
        if (res.code == '200') {
          return res.data
        } else {
          this.loadingInstance.close()
          this.$message.error(res.message)
        }
      })
    },
    closePageScroll() {
      document.body.addEventListener("touchmove", this.defferScroll, {passive: false})
      document.body.addEventListener("wheel", this.defferScroll, {passive: false})
    },
    openPageScroll() {
      document.body.removeEventListener("touchmove", this.defferScroll, {passive: false})
      document.body.removeEventListener("wheel", this.defferScroll, {passive: false})
    }
  }
}

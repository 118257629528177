export const fixContractType = (val,returnSoureData = false)=> {
    let options = [
        {
            value:'0',
            label:'通用合同'
        },
        {
            value:'1',
            label:'CapEX合同'
        },
        {
            value:'2',
            label:'OpEX合同'
        }
    ]
    if(returnSoureData) return options
    let target = options.find(i=>i.value === String(val))
    return target?target.label:'-'
}
export const kgStageFormatter =(v) => {
    if(v ==1) {
           return '投资阶段'
    }
    else if(v==2) {
          return '退出阶段'
    }
  }
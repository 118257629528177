<template>
  <div>
    <el-dialog v-loading.fullscreen.lock="fullscreenLoading" :append-to-body="true" @close="closeDialog" :visible.sync="visible" :title="title" width="960px" :close-on-click-modal="false">
      <h2 class="dialog-title">
        <p>
          <i class="icon title"></i>指派记录
        </p>
        <span @click="addDesigntedPerson">
          <svg-icon data_iconName="regular" className="add"></svg-icon>
          新增指派人
        </span>
      </h2>
      <!-- 表单 -->
      <el-table ref="table" class="table" :data="tableData" v-loading="assignLoading" border :cell-style="{ borderColor: '#E5E5E5' }" :header-cell-style="{borderColor: '#E5E5E5'}">
        <el-table-column prop="operatorNo" label="指派人" width="120"></el-table-column>
        <el-table-column prop="designeeNo" label="被指派人" width="162">
          <template slot-scope="scope">
<!--            <el-select class="assigned-select" :disabled="scope.row.status !== '-'" filterable @visible-change="changeDesigneeList" v-model="scope.row.designeeNo" placeholder="请选择">-->
             <el-select class="assigned-select" :disabled="scope.row.status !== '-'" filterable v-model="scope.row.designeeNo" placeholder="请选择">
              <div v-for="item in designeeList" :key="item.id">
                <el-option
                  :value="item.id"
                  :label="item.NAME"
                  >
                  <!-- :disabled="tableData.filter(x => x.designeeNo === item.id).length" -->
                </el-option>
              </div>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="权限" width="">
          <template slot-scope="scope">
            <ul class="table-qx-list">
              <li>
                <input type="checkbox" class="default" disabled>合同查看
              </li>
              <li>
                <input :disabled="scope.row.status !== '-' || !isPlaunut" :class="(scope.row.status !== '-' || !isPlaunut )&& scope.row.modificationLimit === 'Y'?'default':''" type="checkbox" :checked="scope.row.modificationLimit === 'Y'" @change="e => e.target.checked ? scope.row.modificationLimit = 'Y' : scope.row.modificationLimit = 'N'">发起变更
              </li>
              <li>
                <input :disabled="scope.row.status !== '-' || !isPlaunut" :class="(scope.row.status !== '-' || !isPlaunut )&& scope.row.paymentLimit === 'Y'?'default':''" type="checkbox" :checked="scope.row.paymentLimit === 'Y'" @change="e => e.target.checked ? scope.row.paymentLimit = 'Y' : scope.row.paymentLimit = 'N'">发起付款
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column prop="designeeDate" label="指派时间" width="130"></el-table-column>
        <el-table-column prop="" label="指派状态" width="120">
          <template slot-scope="scope">
            <i v-if="scope.row.designeeStatus" :class="['icon_status', scope.row.status]"></i>
            <span>{{scope.row.designeeStatus || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="72">
          <template slot-scope="scope">
            <span v-if="scope.row.status !== 'reveice' && scope.row.status !== 'pending'" class="cancel-assigned-btn" @click="cancelAssign(scope.row.designeeNo, scope.$index)">取消指派</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <footer>
        <span class="confirm" @click="confirmAssigned">确定</span>
        <span class="cancel" @click="closeDialog">取消</span>
      </footer>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '合同指派',
      visible: true,
      assignLoading: false,
      tableData: [],
      designeeList: [],
      fullscreenLoading: false
    }
  },
  props: {
    dialogTableVisible: {
      type: Boolean,
      default: () => false
    },
    id:{
      type: String,
      default: ''
    },
    currentUserName: {
      type: String,
      default: ''
    },
    bizFlag:{}
  },
  computed:{
    // 1-投管 0-其他 
    // 1-禁止勾选 
    isPlaunut() {
      return this.bizFlag!=1
    }
  },
  methods: {
    confirmAssigned() {
      let _arr = this.tableData.filter(x => x.designeeNo === '')
      if (_arr.length) {
        this.$message.error('被指派人不能为空！')
        return
      }
      this.fullscreenLoading = true
      const obj = {
        params: {
          bizId: this.id,
          assignmentRecords: this.tableData
        }
      }
      this.$request.post(this.URL.startAssignment, obj).then(res => {
        this.closeDialog()
        if (res.code === '200') {
          this.fullscreenLoading = false
          this.$message.success(res.data)
        }
      })
    },
    closeDialog() {
      this.$emit('closeAssignedDialog', false)
    },
    init() {
      this.getAssignmentList()
    },
    // 获取指派列表信息
    getAssignmentList() {
      this.assignLoading = true
      let obj = {
        params: {
          id: this.id
        }
      }
      this.$request.post(this.URL.getAssignmentList, obj).then(res => {
        let list = res.data?.assignmentRecords
        if (!list.length) {
          list.push({
            "viewLimit": "Y",
            "modificationLimit": !this.isPlaunut?"N":"Y",
            "paymentLimit": !this.isPlaunut?"N":"Y",
            "designeeNo": "",
            "designeeDate": '-',
            "designeeStatus": '',
            "status": '-',
            "operatorNo": this.currentUserName
          })
        } else {
          list.forEach(item => {
            if(!this.isPlaunut) {
              item.modificationLimit = item.paymentLimit = 'N'
            }
           
            switch (item.designeeStatus) {
              case '待处理':
                item.status = 'pending'
                break
              case '已接受':
                item.status = 'reveice'
                break
              case '已拒绝':
                item.status = 'reject'
                break
              default:
                item.status = '-'
            }
          })
        }
        this.tableData = list
        this.designeeList = res.data?.designeeList
        this.assignLoading = false
      })
    },
    // 添加指派
    addDesigntedPerson() {
      this.tableData.unshift({
        "viewLimit": "Y",
        "modificationLimit": !this.isPlaunut?"N":"Y",
        "paymentLimit": !this.isPlaunut?"N":"Y",
        "designeeNo": "",
        "designeeDate": '-',
        "designeeStatus": '',
        "status": '-',
        "operatorNo": this.currentUserName
      })
    },
    // 取消指派
    cancelAssign(oa, index) {
      if (oa) {
        this.tableData = this.tableData.filter(x => x.designeeNo !== oa)
      } else {
        this.tableData.splice(index, 1)
      }
      // this.changeDesigneeList(false)
    },
    // 添加,删除 委派列表
    changeDesign(oa) {
      this.tableData = this.tableData.filter(x => x.designeeNo !== oa)
    },
    // 添加,删除 委派列表
    changeDesigneeList(val) {
      // if (!val) {
        this.designeeList.forEach(x => {
          x.disabled = false
          let _arr = this.tableData.filter(item => item.designeeNo === x.id)
          if (_arr.length) x.disabled = true
        })
        this.$forceUpdate()
      // }
    }
  },
  created() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.assigned-select{
  ::v-deep .el-input__inner{
    height: 25px;
  }
  ::v-deep .el-input__icon{
    line-height: 35px;
  }
  ::v-deep .el-select .el-input .el-select__caret.is-reverse{
    margin-top: -4px;
  }
}
::v-deep .el-dialog__title{
  font-size: 14px;
  color: #323232;
  font-weight: 400;
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #EAEAEA;
}
::v-deep .el-dialog__body{
  padding: 12px 18px;
  box-sizing: border-box;
}
::v-deep .el-table{
  margin-top: 12px;
}
::v-deep .el-table__body{
  width: 100%;
}
::v-deep .el-table--border::after, .el-table--group::after, .el-table::before{
  background: none;
}
::v-deep .el-select{
  padding-right: 10px;
  box-sizing: border-box;
}
.dialog-title{
  font-size: 12px;
  font-weight: 600;
  color: #323232;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  line-height: 30px;
  i.icon{
    display: inline-block;
  }
  i.icon.title{
    position: absolute;
    width: 3px;
    height: 17px;
    background: #EFD193;
    top: 73px;
    left: 0px;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    width: 108px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    background: #F0D8A8;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    .add{
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-top: -3px;
      margin-right: 7px;
    }
  }

}
.table-qx-list{
  width: 100%;
  display: flex;
  // justify-content: space-between;
  // padding: 0 2px;
  padding-right: 11px;
  box-sizing: border-box;
  li{
    margin-right: 32px;
    font-size: 12px;
    color: #323232;
    input[type="checkbox"]{
      vertical-align: middle;
      margin-right: 8px;
      border: none;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      display: inline-block;
      &::before{
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 1px solid #D6D6D6;
        border-radius: 2px;
        box-sizing: border-box;
      }
    }
    input[type="checkbox"]:checked{
      &::before{
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        background: url(../assets/img/icon_checked.png) no-repeat center center;
        background-size: 100%;
        border: none;
      }
    }
    input.default{
      &::before{
        background: url(../assets/img/icon_checked_default.png) no-repeat center center;
        background-size: 100%;
        border: none;
      }
    }

  }
}
.cancel-assigned-btn{
  font-size: 12px;
  color: #997236;
  cursor: pointer;
}
.table{
  .icon_status{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 6px;
    &.pending{
      background: #D6D6D6;  //  待处理
    }
    &.reveice{
      background: #5AC2A7;  //  已接受
    }
    &.reject{
      background: #FA5F87;  //  已拒绝
    }
  }
}
footer{
  width: 100%;
  height: 54px;
  padding-top: 12px;
  box-sizing: border-box;
  span{
    float: right;
    width: 72px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #997236;
    font-size: 12px;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    &.cancel{
      background: #FFFFFF;
      border: 1px solid #F0D8A8;
    }
    &.confirm{
      background: #F0D8A8;
      color: #323232;
      margin-left: 12px;
    }
  }
}
</style>
